// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-avisodeprivacidad-index-jsx": () => import("./../../../src/pages/avisodeprivacidad/index.jsx" /* webpackChunkName: "component---src-pages-avisodeprivacidad-index-jsx" */),
  "component---src-pages-contacto-index-jsx": () => import("./../../../src/pages/contacto/index.jsx" /* webpackChunkName: "component---src-pages-contacto-index-jsx" */),
  "component---src-pages-cv-index-jsx": () => import("./../../../src/pages/cv/index.jsx" /* webpackChunkName: "component---src-pages-cv-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-index-jsx": () => import("./../../../src/pages/nosotros/index.jsx" /* webpackChunkName: "component---src-pages-nosotros-index-jsx" */)
}

